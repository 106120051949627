import React from "react";

import imgLogo from "assets/img/wedding/logo-invite-black.png";
import grfxSuccess from "assets/img/wedding/grfx-success.png";
import { Container, Section } from "components/anti/grid/grid";

const InvitationAttend = () => {
  return (
    <>
      <Section className="sc-inv-attend bg-pale py-4">
        <Container className="text-center mb-5">
          <div className="header-logo">
            <img src={imgLogo} alt="Invite logo" />
          </div>
        </Container>

        <Container className="mw-md mx-auto">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-5 mb-sm-down-4 text-center">
              <img src={grfxSuccess} alt="Success" />
            </div>
            <div className="col-12 col-md-7 d-flex align-items-center text-center">
              <div>
                <h2>Thank you for your response!</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                  ultricies tincidunt mi eu fermentum. Duis convallis, felis vel
                  maximus.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  );
};

export default InvitationAttend;
