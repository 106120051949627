import React from "react";
import { navigate } from "gatsby";
import { useQuery, gql, useMutation } from "@apollo/client";
import moment from "moment";

import { Cover } from "../components/anti/cover/cover";
import { Container, Section } from "../components/anti/grid/grid";
import { Button } from "../components/anti/buttons/buttons";

import coverImg from "../assets/img/wedding/bg-login.jpg";

import imgLogo from "../assets/img/wedding/logo-invite-white.png";

const GUEST_DETAILS = gql`
  query GuestDetails($code: String!) {
    Guest(id: "1", code: $code) {
      id
      name
      passcode
      status
      table {
        number
        rsvp {
          title
          event {
            id
            name
            eventDate
            startTime
            location
            address
            link
          }
        }
      }
    }
  }
`;

const UPDATE_STATUS = gql`
  mutation updateGuestStatus($code: String!, $status: String) {
    updateGuestStatus(id: 25, code: $code, status: $status) {
      id
      name
      status
    }
  }
`;

const Invitation = ({ code }) => {
  const { data } = useQuery(GUEST_DETAILS, {
    variables: { code },
  });

  const eventDetails = data && data.Guest && data.Guest.table.rsvp.event;
  const eventName = eventDetails && eventDetails.name.split(" ");

  const [
    updateStatus,
    { data: updateResponse, loading: updateLoading },
  ] = useMutation(UPDATE_STATUS);

  const notAttendHandler = () => {
    updateStatus({ variables: { code, status: "NOTATTEND" } })
      .then(() => navigate(`/invitation/submitted`))
      .catch((err) => console.log(err));
  };

  const attendHandler = () =>
    navigate(`/invitation/${code}/attend`, {
      state: { guestId: data?.Guest?.id },
    });

  const guestStatus = data?.Guest?.status;
  const submitted =
    guestStatus === "ATTEND" ||
    guestStatus === "CHECKIN" ||
    guestStatus === "NOTATTEND";

  const postiveAnswer = guestStatus === "ATTEND" || guestStatus === "CHECKIN";
  const negativeAnswer = guestStatus === "NOTATTEND";

  return (
    <>
      {/* <Cover variant='basic' /> */}
      <Cover
        img={coverImg}
        contentMaxWidth="mw-100"
        imgHeight="h-300px h-xs-400px h-md-500px"
        className="cover-invitation text-center"
      >
        <div className="cover-brand">
          <img src={imgLogo} alt="Invite logo" />
        </div>
        <h2>
          {data?.Guest?.table?.rsvp?.event?.name}
          <div>Tying the Knot</div>
        </h2>
      </Cover>
      <Section className="section-invitation">
        <div className="text-center mb-5">
          <h3>Dear, {data?.Guest?.name}</h3>
          <p>You’re Invited to attend the wedding reception of</p>
        </div>
        <Container>
          <div className="row box mx-auto">
            <div className="col-12 col-md-6 text-center">
              <h3 className="h2">{eventName && eventName[0]}</h3>
              <h3 className="h2">{eventName && eventName[1]}</h3>
              <h3 className="h2">{eventName && eventName[2]}</h3>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="mb-4">
                <strong>
                  {eventDetails &&
                    moment(eventDetails.eventDate).format("dddd, DD MMMM YYYY")}
                </strong>
                <div>
                  <span>
                    {eventDetails &&
                      moment(eventDetails.eventDate).format("HH:mm")}
                  </span>
                </div>
              </div>
              <div>
                <strong>{eventDetails && eventDetails.location}</strong>
                <div>
                  <span>{eventDetails && eventDetails.address}</span>
                </div>
                <div className="mt-2">
                  <a target="_blank" href={eventDetails && eventDetails.link}>
                    View Direction
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <Section className="section-confirmation">
        <Container className="text-center">
          <p className="mb-0">Kindly reply by</p>
          <p className="font-weight-bold">Sunday, 18th May 2020</p>

          <div className="line line-vertical my-2"></div>

          <div className="row">
            <div className="col-12 col-lg-4 py-1">
              <h3>Will you be attending?</h3>
            </div>
            <div className="col-12 col-lg-4 py-1">
              <Button
                variant="outline-primary"
                className={`w-100 ${postiveAnswer ? "active" : ""}`}
                onClick={attendHandler}
                disabled={submitted}
              >
                Yes, wouldn't miss it!
              </Button>
            </div>
            <div className="col-12 col-lg-4 py-1">
              <Button
                variant="outline-primary"
                className={`w-100 ${negativeAnswer ? "active" : ""}`}
                onClick={notAttendHandler}
                disabled={submitted}
              >
                {updateLoading ? (
                  <i className="fas fa-spinner-third fa-spin" />
                ) : (
                  "No, can only celebrate in spirit!"
                )}
              </Button>
            </div>
            {submitted && (
              <div className="col-lg-8 offset-lg-4 mt-4">
                <p className="text-center">You have submitted your answer</p>
              </div>
            )}
          </div>
        </Container>
      </Section>
    </>
  );
};

export default Invitation;
