import React from "react";
import { Router } from "@reach/router";

import Invitation from "../templates/invitation";
import Attend from "../templates/attend";
import Submitted from "../templates/submitted";

const InvitationRouter = () => {
  return (
    <Router>
      <Invitation path="/invitation/:code" />
      <Attend path="/invitation/:code/attend" />
      <Submitted path="/invitation/submitted" />
    </Router>
  );
};

export default InvitationRouter;
