import React, { useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { navigate } from "gatsby";

import { Container, Section } from "components/anti/grid/grid";
import { Button } from "components/anti/buttons/buttons";
import withLocation from "components/anti/with-location";

import imgLogo from "assets/img/wedding/logo-invite-black.png";

const UPDATE_STATUS = gql`
  mutation updateGuestStatus($code: String!, $status: String!) {
    updateGuestStatus(id: 25, code: $code, status: $status) {
      id
      name
      status
    }
  }
`;

const UPDATE_GUEST_STATUS = gql`
  mutation updateGuestAttendance(
    $guestId: String!
    $questionId: ID!
    $name: String
    $attendance: Float!
    $code: String!
  ) {
    updateGuestAttendance(
      guestId: $guestId
      name: $name
      code: $code
      attendance: $attendance
      questionId: $questionId
    ) {
      id
      name
      status
      attendance
    }
  }
`;

const GUEST_DETAILS = gql`
  query GuestDetails($code: String!) {
    Guest(id: "1", code: $code) {
      status
    }
  }
`;

const InvitationAttend = ({ code, location }) => {
  const [person, setPerson] = useState(1);
  const [restriction, setRestriction] = useState();

  const { data: guestStatus } = useQuery(GUEST_DETAILS, {
    variables: { code },
  });

  const [updateStatus, { loading }] = useMutation(UPDATE_STATUS);
  const [updateGuestStatus, { loading: updateLoading }] = useMutation(
    UPDATE_GUEST_STATUS
  );

  useEffect(() => {
    const status = guestStatus?.Guest?.status;

    if (status === "ATTEND" || status === "NOTATTEND" || status === "CHECKIN") {
      navigate("/");
    }
  }, [guestStatus]);

  const guestId = location.state?.guestId;

  const setOnePerson = () => setPerson(1);
  const setTwoPerson = () => setPerson(2);

  const onSubmit = () =>
    updateStatus({
      variables: {
        code: code,
        status: "ATTEND",
        attendance: person,
      },
    })
      .then(() => {
        updateGuestStatus({
          variables: {
            guestId,
            name: restriction,
            attendance: person,
            code,
            questionId: 3,
          },
        })
          .then(() => {
            navigate("/invitation/submitted");
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

  const restrictionInput = (e) => setRestriction(e.target.value);

  return (
    <>
      <Section className="sc-inv-attend bg-pale py-4">
        <Container className="text-center mb-5">
          <div className="header-logo">
            <img src={imgLogo} alt="Invite logo" />
          </div>

          <div className="go-back mb-3">
            <a href="#">Back to Invitation</a>
          </div>

          <div className="mw-xs mx-auto">
            <h2>RSVP for John & Jane</h2>
            <h2>Tying The Knot</h2>
          </div>
        </Container>

        <Container>
          <div className="mw-sm mx-auto">
            <div className="mb-3">
              <div className="row">
                <span>Number of guest attending</span>
              </div>
              <div className="row">
                <Button
                  variant={person === 1 ? "primary" : "light"}
                  className="col-6"
                  onClick={setOnePerson}
                >
                  1 Person
                </Button>
                <Button
                  variant={person === 2 ? "primary" : "light"}
                  className="col-6"
                  onClick={setTwoPerson}
                >
                  2 People
                </Button>
              </div>
            </div>

            <div className="mb-3">
              <div className="row">
                <span>Restriction Note</span>
              </div>
              <div className="row">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex: egg, seafood, dairy"
                  onChange={restrictionInput}
                />
              </div>
            </div>

            {/* <div className="mb-3">
              <div className="row">
                <span>Question</span>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="radio-button">
                    Default Radio
                    <input type="radio" name="question1" className="mb-0" />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="radio-button">
                    Default Radio
                    <input type="radio" name="question1" className="mb-0" />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <div className="row">
                <span>Question</span>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="radio-button">
                    Default Radio
                    <input type="radio" name="question2" className="mb-0" />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="radio-button">
                    Default Radio
                    <input type="radio" name="question2" className="mb-0" />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div> */}

            <div className="row mt-5">
              <Button
                disabled={loading || updateLoading}
                variant="primary"
                className="col-12"
                onClick={onSubmit}
              >
                {loading || updateLoading ? (
                  <i className="fas fa-spinner-third fa-spin" />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </div>
        </Container>
      </Section>
    </>
  );
};

export default withLocation(InvitationAttend);
